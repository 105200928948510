import React, {useEffect, useState} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
);


export function ExponentialDiagramForBuyPool({startPrice, delta, amount, slideValue}) {
    const [dataDiagram, setDataDiagram] = useState([])
    const [labels, setLabels] = useState([])

    useEffect(() => {
        setDataDiagram([]);
        function ExponentialCurveForBuy() {
            const newData = [];
            for(let i = 0; i <= amount - 1; i++){
                let price = startPrice * Math.pow(1 - delta/100, i)
                newData.push(price)
            }
            setDataDiagram(newData);
        }

        ExponentialCurveForBuy()

    }, [startPrice, delta, amount, setDataDiagram]);


    const generatePointBackgroundColors = () => {
        return Array.from({ length: amount }, (_, i) =>
            i < slideValue ? "#E65634" : "#FFFFFF"
        );
    }


    useEffect(() => {
        const newLabels = Array.from({ length: amount }, (_, i) => (i + 1).toString());
        setLabels(newLabels);
    }, [amount, setLabels]);


    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: null,
            },
        },
        scales: {
            y: {
                ticks: {color: '#FFFFFF', beginAtZero: true},
                grid: {
                    display: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                    color: '#8E8E8E'
                }
            },
            x: {
                ticks: {color: '#FFFFFF', beginAtZero: true},
                grid: {
                    display: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                    color: '#2B282C'
                }
            }
        },
    };


    const data = {
        labels,
        datasets: [
            {
                data: dataDiagram,
                borderColor: 'rgb(255,255,255)',
                backgroundColor: 'rgba(77,50,42,0.59)',
                pointBorderWidth: 7,
                pointBorderColor: generatePointBackgroundColors,
                pointBackgroundColor: '#E65634',
                fill: true,
                color: '#FFFFFF',
            },
        ],
    };

    return <Line options={options} data={data} width={881} height={309} />;
}
