import axios from "axios";
import {
    ATOMIC_ASSETS,
    ATOMIC_ASSETS_API,
    EOSIO_TOKEN,
    TOKEN_CONTRACT,
    WASWASWASWAS_CONTRACT
} from "../constants/wax.constants";
import {fetchRows, signTransaction, signTransactions} from "../helpers";



// Get balance
export const getBalance = async ({user}) => {
    const {rows} = await fetchRows({
        contract: TOKEN_CONTRACT,
        scope: user,
        table: 'accounts',
        limit: 100,
    });

    if (rows[0]?.balance) {
        return rows[0].balance;
    } else {
        return "0 ALCH";
    }
}

// Deposit to buy pool
export const depositToken = async ({ activeUser, amount, pool_id }) => {
    return await signTransaction({
        activeUser,
        account: EOSIO_TOKEN,
        action: 'transfer',
        data: {
            from: activeUser.accountName,
            to: WASWASWASWAS_CONTRACT,
            quantity: amount + '.00000000 WAX',
            memo: `deposit pool:${pool_id}`
        }
    });
};

// Send NFT to buy pool
export const sendAssets = async (activeUser, assets, pool_id) => {
    return await signTransaction({
        activeUser,
        account: ATOMIC_ASSETS,
        action: 'transfer',
        data: {
            from: activeUser.accountName,
            to: WASWASWASWAS_CONTRACT,
            asset_ids: assets,
            memo: `sell NFTs:${pool_id}`
        }
    });
};

// Update Buy pool
export const updateBuyPool = async (activeUser, pool_id, newData) => {
    const { new_token_contract, new_schemas, new_templates, new_NFTs_collection, new_starting_floor_price, new_linear_delta, new_exponential_delta } = newData;

    return await signTransaction({
        activeUser,
        account: ATOMIC_ASSETS,
        action: 'uptbuypool',
        data: {
            pool_id,
            new_token_contract,
            new_schemas,
            new_templates,
            new_NFTs_collection,
            new_starting_floor_price,
            new_linear_delta,
            new_exponential_delta
        }
    });
};


// Create buy pool
export const buyPoolInit = async (activeUser, floor_price, NFTs_collection, schema, templates, delta, exponDelta, waxTokenConfig, totalPrice) => {

    const [decimalPlaces, token] = waxTokenConfig.split(',').map(s => s.trim());
    const starting_floor_price = `${Number(floor_price).toFixed(decimalPlaces)} ${token}`;
    const linear_delta = delta * Math.pow(10, decimalPlaces);



    let pools = []

    await getBuyPoolsByCollections()
        .then((data) => pools.push(...data))
        .catch((error) => console.log(error))

    const lastPool = pools[pools.length - 1];
    const nextPoolId = lastPool ? lastPool.pool_id + 1 : 1;


    const actions = [
        {
            account: WASWASWASWAS_CONTRACT,
            action: 'initbuypool',
            data: {
                pool_owner: activeUser.accountName,
                token_contract: EOSIO_TOKEN,
                schemas: schema,
                templates: templates,
                NFTs_collection: NFTs_collection,
                starting_floor_price: starting_floor_price,
                linear_delta: linear_delta,
                exponential_delta: exponDelta
            }
        },
        {
            account: EOSIO_TOKEN,
            action: 'transfer',
            data: {
                from: activeUser.accountName,
                to: WASWASWASWAS_CONTRACT,
                quantity: `${Number(totalPrice).toFixed(decimalPlaces)} ${token}`,
                memo: `deposit pool:` + Number(nextPoolId)
            }
        }
    ]

    return await signTransactions({
        activeUser,
        actions
    });
};

// Create sell pool
export const sellPoolInit = async (activeUser, floor_price, NFTs_collection, schema, templates, delta, exponDelta, waxTokenConfig, selectedItemFromSell) => {
    console.log(selectedItemFromSell)

    const [decimalPlaces, token] = waxTokenConfig.split(',').map(s => s.trim());
    const starting_floor_price = `${Number(floor_price).toFixed(decimalPlaces)} ${token}`;
    const linear_delta = delta * Math.pow(10, decimalPlaces);

    console.log(schema, templates,NFTs_collection, starting_floor_price, linear_delta, exponDelta)

    let pools = []

    await getSellPoolsByCollections()
        .then((data) => pools.push(...data))
        .catch((error) => console.log(error))

    const lastPool = pools[pools.length - 1];
    const nextPoolId = lastPool ? lastPool.pool_id + 1 : 1;

    const actions = [
        {
            account: WASWASWASWAS_CONTRACT,
            action: 'initsellpool',
            data: {
                pool_owner: activeUser.accountName,
                token_contract: EOSIO_TOKEN,
                schemas: schema,
                templates: templates,
                NFTs_collection: NFTs_collection,
                starting_floor_price: starting_floor_price,
                linear_delta: linear_delta,
                exponential_delta: exponDelta
            }
        },
        {
            account: ATOMIC_ASSETS,
            action: 'transfer',
            data: {
                from: activeUser.accountName,
                to: WASWASWASWAS_CONTRACT,
                asset_ids: selectedItemFromSell.map((item) => item.asset_id),
                memo: `deposit NFTs:` + nextPoolId,
            }
        }
    ]

    return await signTransactions({
            activeUser,
            actions
        }
    );
};


// Buy NFTs from Sell pool
export const BuyNfts = async (activeUser, poolId, amount, waxTokenConfig, selectedAssets) => {
    const [decimalPlaces, token] = waxTokenConfig.split(',').map(s => s.trim());

    console.log(decimalPlaces, poolId, amount, waxTokenConfig)


    const actions = [
        {
            account: EOSIO_TOKEN,
            action: 'transfer',
            data: {
                from: activeUser.accountName,
                to: WASWASWASWAS_CONTRACT,
                quantity: `${Number(amount).toFixed(decimalPlaces)} ${token}`,
                memo: 'deposit tokens',
            }
        },
        {
            account: WASWASWASWAS_CONTRACT,
            action: 'buynfts',
            data: {
                buyer: activeUser.accountName,
                pool_id: poolId,
                nfts_to_buy: selectedAssets,
            }
        },
    ]

    return await signTransactions({
        activeUser,
        actions
    });
};



// Get all collections from Atomic Hub
export async function getCollections() {
    let currentPage = 1;
    let result = [];

    while (true) {
        const { data } = await axios.get(`${ATOMIC_ASSETS_API}/collections?page=${currentPage}&limit=1000`);

        if (data.data.length) {
            result.push(...data.data);
            currentPage++;
        } else {
            break;
        }

        await new Promise(resolve => setTimeout(resolve, 400)); // Wait for 1 second before making the next request
    }

    return result;
}

export async function getCollectionsSearch(searchTerm) {
    let result = [];

        const { data } = await axios.get(`${ATOMIC_ASSETS_API}/collections?match=${searchTerm}&limit=1000`);
        if (data.data.length) {
            const filteredData = data.data.filter(collection => collection.collection_name.includes(searchTerm));
            result.push(...filteredData);
        }

        await new Promise(resolve => setTimeout(resolve, 1000));

    return result;
}

export async function getCollectionByName(name) {
    const { data } = await axios.get(`${ATOMIC_ASSETS_API}/collections/${name}`)
    return data.data
}


export async function getUserCollections(activeUser) {
    const response = await axios.get(`${ATOMIC_ASSETS_API}/accounts/${activeUser.accountName}?hide_offers=false`);
    const collections = response.data?.data?.collections || [];
    const collectionNames = collections.map(({ collection }) => collection?.collection_name).filter(Boolean);
    return collectionNames;
}

export async function getCollectionSchema(collection) {
    const { data } = await axios.get(`${ATOMIC_ASSETS_API}/schemas?collection_name=${collection}&page=1&limit=100`)
    return data.data
}

export async function getTemplate(collection, schema) {
    const { data } = await axios.get(`${ATOMIC_ASSETS_API}/templates?collection_name=${collection}&schema_name=${schema}&page=1&limit=100`)
    return data.data
}

export async function getMyNFTs(owner, collectionName) {
    const { data } = await axios.get(`${ATOMIC_ASSETS_API}/assets?owner=${owner}&collection_name=${collectionName}&page=1&limit=100&order=desc&sort=asset_id`)
    return data.data
}

export async function getMyAllNFTs(owner) {
    const { data } = await axios.get(`${ATOMIC_ASSETS_API}/assets?owner=${owner.accountName}&page=1&limit=100&order=desc&sort=asset_id`)
    return data.data
}


export const getSellPoolsByCollections = async () => {
    const {rows} = await fetchRows({
        contract: WASWASWASWAS_CONTRACT,
        scope: WASWASWASWAS_CONTRACT,
        table: 'sellpool',
        limit: 100,
    });

    return rows
}

// Get main config
export const getMainConfig = async () => {
    const {rows} = await fetchRows({
        contract: WASWASWASWAS_CONTRACT,
        scope: WASWASWASWAS_CONTRACT,
        table: 'mainconf',
        limit: 100,
    });

    return rows[0]
}

// Get fees config
export const getFeesConfig = async () => {
    const {rows} = await fetchRows({
        contract: WASWASWASWAS_CONTRACT,
        scope: WASWASWASWAS_CONTRACT,
        table: 'feesconfig',
        limit: 100,
    });

    return rows
}

export const getBuyPoolsByCollections = async () => {
    const {rows} = await fetchRows({
        contract: WASWASWASWAS_CONTRACT,
        scope: WASWASWASWAS_CONTRACT,
        table: 'buypool',
        limit: 100,
    });

    return rows
}

export const getNftsByIds = async (asset_ids) => {
    const requests = asset_ids.map((asset_id) => {
        return axios.get(`${ATOMIC_ASSETS_API}/assets/${asset_id}`)
    })
    const responses = await Promise.all(requests)
    const data = responses.map((response) => response.data.data)
    return data
}


