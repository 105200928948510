import React, {useEffect, useState, useContext} from 'react';


import {useSelector} from "react-redux";
import OrangeBorderButton from "../../components/OrangeBorderButton/OrangeBorderButton";

// import WaxIcon from '../../images/wax_logo.png'

import {
    getCollectionSchema,
    getCollectionsSearch,
    getFeesConfig,
    getMyAllNFTs,
    getTemplate
} from "../../services/wax.services";

import FirstStep from "../../components/CreatePoolSteps/FirstStep/FirstStep";
import SecondStep from "../../components/CreatePoolSteps/SecondStep/SecondStep";
import ThirdStep from "../../components/CreatePoolSteps/ThirdStep/ThirdStep";
import FourthStep from "../../components/CreatePoolSteps/FourthStep/FourthStep";
import RodalCreatePool from "../../components/Modal/RodalCreatePool/RodalCreatePool";

import {BUY, SELL} from "../../constants/pool.constants";
import {EOSIO_TOKEN} from "../../constants/wax.constants";
import {UALContext} from "ual-reactjs-renderer";

import './CreatePool.scss';






const CreatePool = () => {
    const { activeUser } = useContext(UALContext);
    // const allCollections = useSelector(state => state.allCollections)
    const allUserCollections = useSelector(state => state.allUserCollections)
    const [filteredAllCollections, setFilteredAllCollections] = useState([]);

    const [currentSchema, setCurrentSchema] = useState([]);
    const [currentTemplate, setCurrentTemplate] = useState([]);
    const [priceTotal, setPriceTotal] = useState(0)

    const [currentStep, setCurrentStep] = useState(1);
    const [currentStepName, setCurrentStepName] = useState('Selecting pool type');
    const [selectedOption, setSelectedOption] = useState(null);
    const [visible, setVisible] = useState(false);
    const [openList, setOpenList] = useState(false);
    const [openListForSchema, setOpenListForSchema] = useState(false);
    const [openListForTemplate, setOpenListForTemplate] = useState(false);

    const [searchCollectionValue, setSearchCollectionValue] = useState('')
    const [selectedCollection, setSelectedCollection] = useState(null);
    const [selectedSchema, setSelectedSchema] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    const [startPrice, setStartPrice] = useState(0)
    const [delta, setDelta] = useState(0)
    const [exponDelta, setExponDelta] = useState(0)
    const [amount, setAmount] = useState(0)

    const [slideValue, setSliderValue] = useState(0)

    const [price, setPrice] = useState(0)
    const [priceExpon, setPriceExpon] = useState(0)
    const [priceExponTotal, setPriceExponTotal] = useState(0)

    const [addedCollections, setAddedCollections] = useState([])

    const [waxTokenConfig, setWaxTokenConfig] = useState('');

    const [myMFTs,setMyNFTs] = useState();

    const [selectedItemFromSell, setSelectedItemFromSell] = useState([])

    const [totalPrice, setTotalPrice] = useState(0)

    useEffect(() => {
        let floorPrice = Number(startPrice) || 0;

        if (slideValue === 0) {
            setPriceExpon(0);
            return;
        }

        let totalPrice = floorPrice;
        let previousPrice = floorPrice;
        for (let i = 2; i <= slideValue; i++) {
            previousPrice = previousPrice - (previousPrice * (exponDelta / 100));
            totalPrice += previousPrice;
        }

        setPriceExpon(totalPrice);

    }, [slideValue, startPrice, exponDelta]);

    useEffect(() => {
        let floorPrice = Number(startPrice) || 0;

        if (amount === 0) {
            setPriceExponTotal(0);
            return;
        }

        let totalPrice = 0;
        let previousPrice = floorPrice;
        for (let i = 1; i <= amount; i++) {
            totalPrice += previousPrice;
            previousPrice = previousPrice - (previousPrice * (exponDelta / 100));
        }

        setPriceExponTotal(totalPrice.toFixed(8));
        setTotalPrice(totalPrice)

    }, [amount, startPrice, exponDelta]);


    const handleAdd = () => {
        setAddedCollections([{img: selectedCollection?.data?.img, name: selectedCollection.collection_name, schema: selectedSchema, template: selectedTemplate}])
        setOpenList(null)
        setOpenListForSchema(null)
        setOpenListForTemplate(null)
    }

    console.log(addedCollections)


    const deleteAddedCollection = () => {
        setAddedCollections([])
        setSelectedCollection(null)
        setSelectedSchema(null)
        setSelectedTemplate(null)
    }

    const handleSelectOption = (optionIndex) => {
        setCurrentStep(optionIndex + 1);

        if (optionIndex + 1 === 2){
            setCurrentStepName('Selecting Assets')
        }
    };


    useEffect(() => {
        getMyAllNFTs(activeUser)
            .then((data) => {
                setMyNFTs(data)
            })
            .catch(error => {
                console.log(error)
            })
    }, [activeUser])

    useEffect(() => {
        getFeesConfig()
            .then((data) => {
                const filteredData = data.filter((item) => item.token_contract === EOSIO_TOKEN);
                setWaxTokenConfig(filteredData[0].fees[0].key);
            })
            .catch(error => {
                console.log(error);
            })
    }, []);

    // useEffect(() => {
    //     const filterCollections = () => {
    //         if (searchCollectionValue === '') {
    //             setFilteredAllCollections(allCollections);
    //         } else {
    //             const filteredCollections = allCollections.filter(collection => collection.collection_name.includes(searchCollectionValue));
    //             setFilteredAllCollections(filteredCollections);
    //         }
    //     }
    //
    //     filterCollections()
    // }, [allCollections, searchCollectionValue])

    useEffect(() => {
        if (searchCollectionValue.length > 4) {
            getCollectionsSearch(searchCollectionValue)
                .then((data) => {
                    setFilteredAllCollections(data);
                })
                .catch((e) => console.log(e));
        }
    }, [searchCollectionValue]);

    useEffect(() => {
        let price = Number(startPrice);
        let currentDelta = Number(delta);

        const calculatePrice = (deltaSign) => {
            if (slideValue === 0) {
                setPrice(0);
                return;
            }

            for (let i = 2; i <= Number(slideValue); i++) {
                price += Number(startPrice) + deltaSign * currentDelta;
                currentDelta += Number(delta);
            }

            setPrice(price);

        };

        if (selectedOption === BUY) {
            calculatePrice(-1);
        }
        if (selectedOption === SELL) {
            calculatePrice(1);
        }
    }, [startPrice, delta, amount, slideValue, selectedOption]);

    useEffect(() => {
        function calculateTotal(sign) {
            let price = Number(startPrice);
            let currentDelta = Number(delta);
            let amountLeft = Number(amount);

            let total = price;

            for (let i = 1; i < amountLeft; i++) {
                price -= currentDelta * sign;
                if (price <= 0) {
                    total += 0;
                    setAmount(i)
                    break;
                }
                total += price;
            }

            return total.toFixed(8);
        }

        if (selectedOption === BUY) {
            const total = calculateTotal(1);
            setPriceTotal(total);

            setTotalPrice(total)
        }

        if (selectedOption === SELL) {
            const total = calculateTotal(-1);
            setPriceTotal(total);

            setTotalPrice(total)
        }



    }, [startPrice, delta, amount, selectedOption]);


    useEffect(() => {
        if (selectedCollection)
            getCollectionSchema(selectedCollection?.collection_name)
                .then((data) => {
                    setCurrentSchema(data)
            })
                .catch((error) => {
                console.log(error)
            })
    }, [selectedCollection])

    useEffect(() => {
        if (selectedSchema)
            getTemplate(selectedCollection?.collection_name, selectedSchema)
                .then((data) => {
                    setCurrentTemplate(data)
                })
                .catch((error) => {
                    console.log(error)
                })
    },[selectedSchema])


    const show = () => {
        setVisible(true);
    };

    const hide = () => {
        setVisible(false);
    };

    const handleSelectCollection = (item) => {
        setSelectedCollection(item)

        // if(item === null){
        //     setSelectedCollection(null)
        // }

        setSelectedSchema([])
        setSelectedTemplate([])
        setSelectedItemFromSell([])

        setOpenList(false)
    }

    const handleSelectSchema = (item) => {
        const index = selectedSchema.indexOf(item);

        if (index === -1) {
            setSelectedSchema([...selectedSchema, item]);
        } else {
            const updatedSchema = [...selectedSchema];
            updatedSchema.splice(index, 1);
            setSelectedSchema(updatedSchema);
        }

        if(selectedSchema.length === 0){
            setSelectedTemplate([])
        }

    };

    console.log(selectedSchema)


    const handleSelectTemplate = (item) => {
        const index = selectedTemplate.indexOf(item);

        if (index === -1) {
            setSelectedTemplate([...selectedTemplate, item]);
        } else {
            const updatedTemplate = [...selectedTemplate];
            updatedTemplate.splice(index, 1);
            setSelectedTemplate(updatedTemplate);
        }
    }


    const handlerOpenList = () => {
        setOpenList(!openList);
    }

    const handlerOpenListForSchema = () => {
        setOpenListForSchema(!openListForSchema);
    }

    const handlerOpenListForTemplate = () => {
        setOpenListForTemplate(!openListForTemplate);
    }

    function handleCurrentPrevStep() {
        setCurrentStep(currentStep - 1)
        setStartPrice(0)
        setDelta(0)
        setAmount(0)
    }

    useEffect(() => {
        if(currentStep === 3)
            setCurrentStepName('Configuring Pool Parameters')

        if(currentStep === 4)
            setCurrentStepName('Finalizing Deposit')
    }, [currentStep])


    return (
        <>
            <div className="create-pool-title">
                <h2>Create Pool</h2>
                <p>Provide liquidity to buy, sell NFTs on your behalf</p>
            </div>
            <div className="create-pool-stepper">
                <div className="create-pool-stepper_banner">
                    <p>Step {currentStep}/4: {currentStepName}</p>
                </div>
                <div className="create-pool-stepper_wrapper">

                    <FirstStep
                        currentStep={currentStep}
                        setSelectedOption={setSelectedOption}
                        handleSelectOption={handleSelectOption}
                    />

                   <SecondStep
                       currentStep={currentStep}
                       selectedOption={selectedOption}
                       selectedCollection={selectedCollection}
                       show={show}
                   />

                    <ThirdStep
                        currentStep={currentStep}
                        selectedOption={selectedOption}
                        setStartPrice={setStartPrice}
                        startPrice={startPrice}
                        setDelta={setDelta}
                        delta={delta}
                        selectedCollection={selectedCollection}
                        setAmount={setAmount}
                        amount={amount}
                        setSliderValue={setSliderValue}
                        slideValue={slideValue}
                        price={price}
                        priceExpon={priceExpon}
                        setExponDelta={setExponDelta}
                        exponDelta={exponDelta}
                        priceTotal={priceTotal}
                        priceExponTotal={priceExponTotal}
                        setPriceExponTotal={setPriceExponTotal}
                        setPriceTotal={setPriceTotal}
                    />
                    <FourthStep
                        currentStep={currentStep}
                        delta={delta}
                        schema={selectedSchema}
                        collection={selectedCollection}
                        templates={selectedTemplate}
                        startPrice={startPrice}
                        waxTokenConfig={waxTokenConfig}
                        exponDelta={exponDelta}
                        selectedOption={selectedOption}
                        selectedItemFromSell={selectedItemFromSell}
                        price={price}
                        priceTotal={priceTotal}
                        priceExponTotal={priceExponTotal}
                        totalPrice={totalPrice}
                    />

                    {currentStep !== 1 &&
                        <div className="stepper-footer">
                            {currentStep === 2 &&
                                <OrangeBorderButton width={136} onClick={() => {
                                    setSelectedCollection(null)
                                    setCurrentStep(currentStep - 1)
                                }}>
                                    <span className={'prev'}> > </span> Prev step </OrangeBorderButton>
                            }
                            {currentStep !== 2 &&
                                <OrangeBorderButton width={136} onClick={() => handleCurrentPrevStep()}><span className={'prev'}> > </span> Prev step </OrangeBorderButton>
                            }


                            {currentStep === 2 && selectedOption === BUY &&
                                <OrangeBorderButton disabled={!selectedCollection} className={selectedCollection ? '' : 'disabled'} width={136} onClick={() => setCurrentStep(currentStep + 1)}>Next step <span className={'next'}> > </span></OrangeBorderButton>
                            }
                            {currentStep === 2 && selectedOption === SELL &&
                                <OrangeBorderButton disabled={!selectedItemFromSell} className={selectedItemFromSell ? '' : 'disabled'} width={136} onClick={() => setCurrentStep(currentStep + 1)}>Next step <span className={'next'}> > </span></OrangeBorderButton>
                            }
                            {currentStep === 3 &&
                                <OrangeBorderButton disabled={startPrice <= 0} className={startPrice > 0 ? '' : 'disabled'} width={136} onClick={() => setCurrentStep(currentStep + 1)}>Next step <span className={'next'}> > </span></OrangeBorderButton>
                            }
                            {currentStep !== 4 && currentStep !== 3 && currentStep !== 2 &&
                                <OrangeBorderButton disabled={!selectedCollection} className={selectedCollection ? '' : 'disabled'} width={136} onClick={() => setCurrentStep(currentStep + 1)}>Next step <span className={'next'}> > </span></OrangeBorderButton>
                            }
                        </div>
                    }
                </div>
            </div>

          <RodalCreatePool
            visible={visible}
            hide={hide}
            handlerOpenList={handlerOpenList}
            selectedCollection={selectedCollection}
            openList={openList}
            setSearchCollectionValue={setSearchCollectionValue}
            allCollections={filteredAllCollections}
            handleSelectCollection={handleSelectCollection}
            handlerOpenListForSchema={handlerOpenListForSchema}
            selectedSchema={selectedSchema}
            openListForSchema={openListForSchema}
            currentSchema={currentSchema}
            handleSelectSchema={handleSelectSchema}
            selectedTemplate={selectedTemplate}
            openListForTemplate={openListForTemplate}
            currentTemplate={currentTemplate}
            handlerOpenListForTemplate={handlerOpenListForTemplate}
            handleSelectTemplate={handleSelectTemplate}
            handleAdd={handleAdd}
            addedCollections={addedCollections}
            deleteAddedCollection={deleteAddedCollection}
            selectedOption={selectedOption}
            myMFTs={myMFTs}
            setSelectedItemFromSell={setSelectedItemFromSell}
            selectedItemFromSell={selectedItemFromSell}
            allUserCollections={allUserCollections}
          />
        </>
    );
};

export default CreatePool;
