import React from 'react';

import { useSelector} from "react-redux";
import FilteredTable from '../../components/FilteredTable/FilteredTable';
import LoaderData from '../../components/LoaderData/LoaderData';

import './CollectionsPage.scss';


const CollectionsPage = () => {
    const allCollections = useSelector(state => state.allCollections)
    // const [allCollections, setAllCollections] = useState();




    return (
        <>
            <div className="collections-page-title">
                <h2>Collections</h2>
                <p>Don’t see a collection you want?</p>
                <p>
                    Directly <span>list your NFTs</span>, or{' '}
                    <span>create a new pool to buy and sell in bulk</span>
                </p>
            </div>
            <div className="collections-page-table">
                {!allCollections.length ? (
                    <LoaderData />
                ) : (
                    <FilteredTable allCollections={allCollections} />
                )}
            </div>
        </>
    );
};

export default CollectionsPage;
