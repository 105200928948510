import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate  } from 'react-router-dom';
import { UALContext } from 'ual-reactjs-renderer';

import {depositToken, getBuyPoolsByCollections, getMainConfig} from '../../services/wax.services';
import {LinearDiagramForBuyPool} from "../../components/LinearDiagramForBuyPool/LinearDiagramForBuyPool";
import SliderS from "../../components/Slider/Slider";

import WaxLogo from '../../images/wax_logo2.png'
import ArrowIcon from '../../images/arrow2.png'


import './PoolPageBuy.scss';
import {ExponentialDiagramForBuyPool} from "../../components/ExponentialDiagramForBuyPool/ExponentialDiagramForBuyPool";
import {toast} from "react-toastify";
import Rodal from "rodal";
import OrangeBorderButton from "../../components/OrangeBorderButton/OrangeBorderButton";




const PoolPageBuy = () => {
    const navigate = useNavigate();
    const { name, id } = useParams();
    const { activeUser } = useContext(UALContext);

    const [currentPool, setCurrentPool] = useState(null);
    const [delta, setDelta] = useState(0);
    const [amountNFT, setAmountNFT] = useState(0);

    const [isEditing, setIsEditing] = useState(false);
    const [editedPool, setEditedPool] = useState(null);
    const [editedDelta, setEditedDelta] = useState(null);

    const [sliderValue, setSliderValue] = useState(0)

    const [amountNft, setAmountNft] = useState(0)

    const [price, setPrice] = useState(0)
    const [priceToken, setPriceToken] = useState(0)

    const [mainConfig, setMainConfig] = useState([]);

    const [isEditingDeposite, setIsEditingDeposite] = useState(false);
    const [amountDeposite, setAmountDeposite] = useState(currentPool?.pool_asset);

    const [finalPrice, setFinalPrice] = useState(0)
    const [finalPriceForExpon, setFinalPriceForExpon] = useState(0)


    const [currentDelta, setCurrentDelta] = useState('Linear')

    const [visible, setVisible] = useState(false);

    const toggleEditing = () => {
        setIsEditingDeposite(!isEditingDeposite);
    };


    const show = () => {
        setVisible(true);
    };

    const hide = () => {
        setVisible(false);
    };

    const handleSave = () => {
        if (amountDeposite){
            handleDeposit(amountDeposite)
            setIsEditingDeposite(false);
        }else {
            setIsEditingDeposite(false);
        }

    };

    useEffect(() => {
        const fetchPool = async () => {
            try {
                const buyPools = await getBuyPoolsByCollections();
                const pool = buyPools.find((item) => item.pool_id === parseInt(id));
                setCurrentPool(pool);


                if (pool.linear_delta === 0 && pool.exponential_delta === "0.00000000000000000") {
                    setCurrentDelta('Linear')
                } else if (pool.linear_delta !== 0) {
                    setCurrentDelta('Linear')
                } else {
                    setCurrentDelta('Exponential')
                }

            } catch (error) {
                console.log(error);
            }
        };

        fetchPool();
    }, [id]);

    useEffect(() => {
        if (currentPool){
            let pool_asset = currentPool.pool_asset.split(' ')[0]
            let floor_price = currentPool.floor_price.split(' ')[0]
            let result = pool_asset / floor_price

            // Отримуємо ціле число та різницю
            let integerResult = Math.floor(result)
            let difference = 1 - (result - integerResult).toFixed(2)

            // Виводимо результати в консоль
            setAmountNft(integerResult)
            // console.log("Різниця: ", difference)
        }
    }, [currentPool])

    console.log(currentDelta)

    useEffect(() => {
        const feePercentage = 1;
        const floorPrice = Number(currentPool?.floor_price?.split(' ')[0]) || 0;

        if (sliderValue === 0) {
            setFinalPrice(0);
            return;
        }

        let totalPrice = floorPrice;
        for (let i = 2; i <= sliderValue; i++) {
            totalPrice += floorPrice - delta * (i - 1);
        }

        let finalPrice = totalPrice * (1 - feePercentage / 100);
        setFinalPrice(finalPrice.toFixed(8));
    }, [sliderValue, currentPool, delta]);

    useEffect(() => {
        let floorPrice = Number(currentPool?.floor_price?.split(' ')[0]) || 0;

        if (sliderValue === 0) {
            setFinalPriceForExpon(0);
            return;
        }

        let totalPrice = floorPrice;
        let previousPrice = floorPrice;
        for (let i = 2; i <= sliderValue; i++) {
            previousPrice = previousPrice - (previousPrice * (delta / 100));
            totalPrice += previousPrice;
        }

        let finalPrice = totalPrice * (1 - 0.01);
        setFinalPriceForExpon(finalPrice.toFixed(8));
    }, [sliderValue, currentPool, delta]);





    useEffect(() => {
        if (!currentPool) return;
        if (currentPool.linear_delta > 0) {
            setDelta(currentPool.linear_delta / Math.pow(10,8));
        } else if (currentPool.exponential_delta !== '0.00000000000000000') {
            setDelta(parseFloat(currentPool.exponential_delta));
        } else {
            setDelta(0);
        }
    }, [currentPool]);

    useEffect(() => {
        getMainConfig()
            .then((data) => {
                setMainConfig(data)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    const handleEditClick = () => {
        show()
    };

    const handleSaveClick = () => {
        setIsEditing(false);



        // setCurrentPool({
        //     ...currentPool,
        //     floor_price: editedPool,
        //     linear_delta: editedDelta,
        // });
    };
    
    const handleDeposit = (amount) => {
        depositToken({activeUser, amount: amount, pool_id: id})
            .then(() => {

                getBuyPoolsByCollections()

                toast.success('Deposited', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const handleClickBack = () => {
        navigate(`/collections/${name}`);
    };

    const handleClickDirect = () => {
        navigate(`/collections/${name}/swap/buy/${id}`);
    }


    return (
        <div className={'pool-page-section'}>
            <div className="pool-page-section_title">
                <div className="wax-title">
                    <img src={WaxLogo} alt=""/>
                    <p>WAX</p>
                </div>
                <div className="arrow">
                    <img src={ArrowIcon} alt=""/>
                </div>
                <div className="name-collection">
                    <p>{name}</p>
                </div>
            </div>

            <div className="action-buttons">
                <button onClick={handleClickBack} className={'view-all'}>View All</button>
                {currentPool?.pool_owner !== activeUser?.accountName &&
                    <button onClick={handleClickDirect} className={'direct-swap'}>Direct Pool Swap</button>
                }
            </div>
            <div className="assets-block-buy">
                <div className="assets">
                    <div className="assets_title">
                        <h5>Assets</h5>
                    </div>

                    <div className="deposit-field">
                        <div className="wrapper">
                            <div className="deposit-field-tokens">
                                <p className="little-title">Tokens</p>
                                {isEditingDeposite ? (
                                    <input
                                        type="number"
                                        value={amountDeposite}
                                        onChange={(e) => setAmountDeposite(e.target.value)}
                                    />
                                ) : (
                                    <p className="amount-token">{currentPool?.pool_asset}</p>
                                )}
                            </div>
                            {activeUser && currentPool && currentPool.pool_owner && activeUser.accountName === currentPool.pool_owner && (
                                <div className="deposit-withdraw-btn">
                                    {isEditingDeposite ? (
                                        <button className="save-btn" onClick={handleSave}>
                                            Save
                                        </button>
                                    ) : (
                                        <button className="deposit-btn" onClick={toggleEditing}>
                                            Deposit
                                        </button>
                                    )}
                                    <button className="withdraw-btn">Withdraw</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="about">
                    <div className="pricing">
                        {activeUser && currentPool && currentPool.pool_owner && activeUser.accountName === currentPool.pool_owner &&
                            <div className="edit">
                                <button>Edit</button>
                            </div>
                        }

                        <div className="pricing_title">
                            <h5>Pricing</h5>
                        </div>
                        <div className="pricing-info">
                            <div className="current-price">
                                <div className="wrapper">
                                    <div className="little-title">
                                        <p>Current Price</p>
                                    </div>
                                        <div className={'floor-price'}>
                                            <p>{currentPool?.floor_price}</p>
                                        </div>
                                </div>
                            </div>
                            <div className="delta">
                                <div className="wrapper">
                                    <div className="little-title">
                                        <p>Delta: {currentDelta}</p>
                                    </div>
                                        <>
                                            {currentDelta === 'Linear' &&
                                                <div className={'delta-number'}>
                                                    <p>{delta} WAX</p>
                                                </div>
                                            }
                                            {currentDelta === 'Exponential' &&
                                                <div className={'delta-number'}>
                                                    <p>{delta}%</p>
                                                </div>
                                            }
                                        </>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="about-owner">
                        <div className="about-owner_title">
                            <h5>About</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="diagram-block">
                <div className="diagram-block_title">
                    <h5>Price Graph</h5>
                </div>
                <div className="amount-slider">
                    <h4>Selling <span>{sliderValue} NFTs</span></h4>
                    <SliderS setSliderValue={setSliderValue} slideValue={sliderValue} amount={amountNft}  />
                    {currentDelta === 'Linear' &&
                        <p>will earn this pool <span>{finalPrice} WAX</span></p>
                    }
                    {currentDelta === 'Exponential' &&
                        <p>will earn this pool <span>{finalPriceForExpon} WAX</span></p>
                    }
                    {/*<p>and <span>{priceToken} SWPD</span></p>*/}
                </div>
                <div className="diagram-content">
                    {currentDelta === 'Linear' &&
                        <LinearDiagramForBuyPool startPrice={currentPool?.floor_price.split(' ')[0]} delta={delta} amount={amountNft} slideValue={sliderValue} />
                    }
                    {currentDelta === 'Exponential' &&
                        <ExponentialDiagramForBuyPool startPrice={currentPool?.floor_price.split(' ')[0]} delta={delta} amount={amountNft} slideValue={sliderValue} />
                    }
                </div>
            </div>

            <Rodal className={'rodal-update-pool'} visible={visible} onClose={hide}>
                <div className="rodal-title">
                    <h3>Update pool</h3>
                </div>
                <div className="rodal-form">
                    {/*<div className="rodal-form_field">*/}
                    {/*    <p>pool_id</p>*/}
                    {/*    <input type="text" value={id}/>*/}
                    {/*</div>*/}
                    {/*<div className="rodal-form_field">*/}
                    {/*    <p>new_token_contract</p>*/}
                    {/*    <input type="text" value={'eosio.token'}/>*/}
                    {/*</div>*/}
                    <div className="rodal-form_field">
                        <p>New schemas</p>
                        <input type="text"/>
                    </div>
                    <div className="rodal-form_field">
                        <p>New Templates</p>
                        <input type="text"/>
                    </div>
                    <div className="rodal-form_field">
                        <p>New NFTs collection</p>
                        <input type="text"/>
                    </div>
                    <div className="rodal-form_field">
                        <p>New floor price</p>
                        <input type="text"/>
                    </div>
                    <div className="rodal-form_field">
                        <p>New linear delta</p>
                        <input type="text"/>
                    </div>
                    <div className="rodal-form_field">
                        <p>New exponential delta</p>
                        <input type="text"/>
                    </div>
                </div>
                <div className="update-btn">
                    <OrangeBorderButton>Update</OrangeBorderButton>
                </div>

            </Rodal>

        </div>
    )
};

export default PoolPageBuy;
