import React, {useState} from 'react'
import SliderS from "../../Slider/Slider";
import {LinearDiagram} from "../../LinearDiagram/LinearDiagram";

import {
    BUY,
    SELL,
} from '../../../constants/pool.constants'
import {ExponentialDiagram} from "../../ExponentialDiagram/ExponentialDiagram";


function ThirdStep({
 currentStep,
 selectedOption,
 setStartPrice,
 startPrice,
 setDelta,
 delta,
 selectedCollection,
 setAmount,
 amount,
 setSliderValue,
 slideValue,
 price,
 priceExpon,
 setExponDelta,
 exponDelta,
 priceTotal,
 setPriceExponTotal,
 priceExponTotal,
 setPriceTotal
}) {

    const [selectedValue, setSelectedValue] = useState('LINEAR');

    function handleSelectChange(newValue) {
        if (newValue === 'LINEAR') {
            setExponDelta(0);
            setPriceExponTotal(0)
        } else {
            setPriceTotal(0)
            setDelta(0);
        }
        setSelectedValue(newValue);
    }

    return (
        <div className={`create-pool-stepper-third ${currentStep !== 3 ? 'hidden' : ''}`}>
            {selectedOption === BUY &&
                <>
                    <div className="count-block">
                        <div className="pool-pricing-block">
                            <div className="pool-pricing-block_wrapper">
                                <div className="pool-pricing-block_title">
                                    <h6>Pool Pricing</h6>
                                    <p>Set the initial price and how your pool’s price changes</p>
                                </div>
                                <div className="start-price">
                                    <p>Start price: </p>
                                    <input type="number" value={startPrice} onChange={(e) => setStartPrice(e.target.value)}/>
                                </div>
                                <div className="bonding">
                                    <p>Bonding Curve</p>
                                    <select name="delta" value={selectedValue} onChange={(e) => handleSelectChange(e.target.value)}>
                                        <option value="LINEAR">Linear</option>
                                        <option value="EXPONENTIAL">Exponential</option>
                                    </select>
                                </div>
                                {selectedValue === 'LINEAR' ?
                                    <div className="delta-price">
                                        <p>Delta </p>
                                        <input type="number" value={delta} onChange={(e) => setDelta(e.target.value)}/>
                                    </div>
                                    :
                                    <div className="delta-price">
                                        <p>Delta %</p>
                                        <input type="number" value={exponDelta} onChange={(e) => setExponDelta(e.target.value)}/>
                                    </div>
                                }
                            </div>

                        </div>
                        <div className="assets-amount-block">
                            <div className="assets-amount-block_wrapper">
                                <div className="assets-amount-block_title">
                                    <h6>Asset Amounts</h6>
                                    <p>Set how many tokens you deposit into the pool</p>
                                </div>
                                <div className="assets-amount-block_amount">
                                    <p>If you want to buy</p>
                                    <input type="number" value={amount}
                                           onChange={(e) => {
                                               const value = parseInt(e.target.value);
                                               if (value > -1) {
                                                   setAmount(value);
                                               }
                                           }}
                                    />
                                </div>
                                {selectedValue === "LINEAR" ?
                                    <div className="assets-amount-block_alert">
                                        <p>you will need to deposit <span>{Number(priceTotal).toFixed(8)} WAX</span> total</p>
                                    </div>
                                    :
                                    <div className="assets-amount-block_alert">
                                        <p>you will need to deposit <span>{Number(priceExponTotal).toFixed(8)} WAX</span> total</p>
                                    </div>
                                }

                                <div className="assets-amount-block_scroll">
                                    <div className="assets-amount-block_scroll-wrapper">
                                        <p className="amount-nft">Buying <span>{slideValue} NFTs</span></p>
                                    </div>
                                    <div className="assets-amount-block_scroll_scroller">
                                        <SliderS amount={amount} slideValue={slideValue} setSliderValue={setSliderValue} />
                                    </div>
                                    {selectedValue === 'LINEAR' ?
                                        <div className="assets-amount-block_cost">
                                            <p>will cost you <span>{Number(price).toFixed(8)} WAX</span></p>
                                        </div>
                                        :
                                        <div className="assets-amount-block_cost">
                                            <p>will cost you <span>{Number(priceExpon).toFixed(8)} WAX</span></p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="diagram-block">
                            <div className="diagram-block_title">
                                <h4>WAX Price per {selectedCollection?.collection_name} NFTs</h4>
                                <p>Displays how your buy price goes down with each NFT bought</p>
                            </div>

                            {selectedValue === 'LINEAR' &&
                                <LinearDiagram selectedOption={selectedOption} startPrice={startPrice} delta={delta} amount={amount} slideValue={slideValue}/>
                            }
                            {selectedValue === 'EXPONENTIAL' &&
                                <ExponentialDiagram selectedOption={selectedOption} startPrice={startPrice} delta={exponDelta} amount={amount} slideValue={slideValue} />
                            }
                    </div>
                </>
            }

            {selectedOption === SELL &&
                <>
                    <div className="count-block">
                        <div className="pool-pricing-block">
                            <div className="pool-pricing-block_wrapper">
                                <div className="pool-pricing-block_title">
                                    <h6>Pool Pricing</h6>
                                    <p>Set the initial price and how your pool’s price changes</p>
                                </div>
                                <div className="start-price">
                                    <p>Start price: </p>
                                    <input type="number" value={startPrice} onChange={(e) => setStartPrice(e.target.value)}/>
                                </div>
                                <div className="bonding">
                                    <p>Bonding Curve</p>
                                    <select name="delta" value={selectedValue} onChange={(e) => handleSelectChange(e.target.value)}>
                                        <option value="LINEAR">Linear</option>
                                        <option value="EXPONENTIAL">Exponential</option>
                                    </select>
                                </div>
                                {selectedValue === 'LINEAR' ?
                                    <div className="delta-price">
                                        <p>Delta </p>
                                        <input type="number" value={delta} onChange={(e) => setDelta(e.target.value)}/>
                                    </div>
                                    :
                                    <div className="delta-price">
                                        <p>Delta %</p>
                                        <input type="number" value={exponDelta} onChange={(e) => setExponDelta(e.target.value)}/>
                                    </div>
                                }
                            </div>

                        </div>
                        <div className="assets-amount-block">
                            <div className="assets-amount-block_wrapper">
                                <div className="assets-amount-block_title">
                                    <h6>Asset Amounts</h6>
                                    <p>Set how many tokens you deposit into the pool</p>
                                </div>
                                <div className="assets-amount-block_amount">
                                    <p>If you want to sell</p>
                                    <input type="number" value={amount}
                                           onChange={(e) => {
                                               const value = parseInt(e.target.value);
                                               if (value > -1) {
                                                   setAmount(value);
                                               }
                                           }}
                                    />
                                </div>
                                <div className="assets-amount-block_alert">
                                    {/*<p>you will need to deposit <span>/280/ WAX</span> total</p>*/}
                                </div>
                                <div className="assets-amount-block_scroll">
                                    <div className="assets-amount-block_scroll-wrapper">
                                        <p className="amount-nft">Buying <span>{slideValue} NFTs</span></p>
                                    </div>
                                    <div className="assets-amount-block_scroll_scroller">
                                        <SliderS amount={amount} slideValue={slideValue} setSliderValue={setSliderValue} />
                                    </div>
                                    {selectedValue === 'LINEAR' ?
                                        <div className="assets-amount-block_cost">
                                            <p>will receive you <span>{Number(price).toFixed(8)} WAX</span></p>
                                        </div>
                                        :
                                        <div className="assets-amount-block_cost">
                                            <p>will receive you <span>{Number(priceExpon).toFixed(8)} WAX</span></p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="diagram-block">
                            <div className="diagram-block_title">
                                <h4>WAX Price per NAME NFTs</h4>
                                <p>Displays how your buy price goes down with each NFT bought</p>
                            </div>
                            {selectedValue === 'LINEAR' &&
                                <LinearDiagram selectedOption={selectedOption} startPrice={startPrice} delta={delta} amount={amount} slideValue={slideValue}/>
                            }
                            {selectedValue === 'EXPONENTIAL' &&
                                <ExponentialDiagram selectedOption={selectedOption} startPrice={startPrice} delta={exponDelta} amount={amount} slideValue={slideValue} />
                            }
                    </div>
                </>
            }

        </div>
    );
}

export default ThirdStep;
