import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";

import './FilteredTable.scss'
import OrangeBorderButton from "../OrangeBorderButton/OrangeBorderButton";



const FilteredTable = ({ allCollections }) => {
    const [sortOrder, setSortOrder] = useState(1);
    const [poolsSortOrder, setPoolsSortOrder] = useState(1);
    const [floorSortOrder, setFloorSortOrder] = useState(1);
    const [bestSortOrder, setBestSortOrder] = useState(1);
    const [volumeSortOrder, setVolumeSortOrder] = useState(1);

    const [displayedCollections, setDisplayedCollections] = useState([]);
    const [displayedCount, setDisplayedCount] = useState(20);

    // const INITIAL_LIMIT = 15; //5 rows
    // const [limit, setLimit] = useState(INITIAL_LIMIT);

    // const onScroll = ({ target: { scrollingElement: { clientHeight, scrollTop, scrollHeight } } }) => {
    //     if (clientHeight + scrollTop >= scrollHeight - 0)
    //         setLimit(Math.min(limit + INITIAL_LIMIT, allCollections.length));
    // }

    // useEffect(() => {
    //     window.addEventListener('scroll', onScroll);
    //
    //     return () => window.removeEventListener('scroll', onScroll);
    // });

    const handleLoadMore = () => {
        setDisplayedCount(displayedCount + 50);
        setDisplayedCollections(allCollections.slice(0, displayedCount + 50));
    };

    const sortByKey = (data, key, sortOrder) => {
        return data.sort((a, b) => {
            const valueA = typeof a[key] === 'string' ? a[key].toLowerCase() : a[key];
            const valueB = typeof b[key] === 'string' ? b[key].toLowerCase() : b[key];
            return sortOrder * (valueA < valueB ? -1 : valueA > valueB ? 1 : 0);
        });
    };

    const filterByName = () => {
        const sortedDataCollection = sortByKey(allCollections, 'collection_name', sortOrder);
        setSortOrder(sortOrder * -1);
        return sortedDataCollection;
    };

    const filterByPool = () => {
        const sortedDataCollection = sortByKey(allCollections, 'pools', poolsSortOrder);
        setPoolsSortOrder(poolsSortOrder * -1);
        return sortedDataCollection;
    };

    const filterByFloor = () => {
        const sortedDataCollection = sortByKey(allCollections, 'floor', floorSortOrder);
        setFloorSortOrder(floorSortOrder * -1);
        return sortedDataCollection;
    };

    const filterByBest = () => {
        const sortedDataCollection = sortByKey(allCollections, 'best', bestSortOrder);
        setBestSortOrder(bestSortOrder * -1);
        return sortedDataCollection;
    };

    const filterByVolume = () => {
        const sortedDataCollection = sortByKey(allCollections, 'volume', volumeSortOrder);
        setVolumeSortOrder(volumeSortOrder * -1);
        return sortedDataCollection;
    };


    return(
        <div className={'filter-table'}>
            <div className="filter">
                <div className="name">
                    <p onClick={() => filterByName()}>name</p>
                </div>
                <div className="pools">
                    <p onClick={() => filterByPool()}>pools</p>
                </div>
                <div className="floor">
                    <p onClick={() => filterByFloor()}>floor</p>
                </div>
                <div className="best">
                    <p onClick={() => filterByBest()}>best</p>
                </div>
                <div className="volume">
                    <p onClick={() => filterByVolume()}>volume</p>
                </div>
            </div>
            {allCollections && allCollections
                .slice(0, displayedCount)
                .map((item =>
                    <Link to={item.collection_name}>
                        <div className="filter-table_item">
                            <div className="name">
                                <div className="avatar">
                                    {item.data.img && <img src={`https://atomichub-ipfs.com/ipfs/${item.data.img}`} alt=""/>}
                                </div>
                                <p>{item.collection_name}</p>
                            </div>
                            <div className="pools">
                                <p>100</p>
                            </div>
                            <div className="floor">
                                <p>2000</p>
                            </div>
                            <div className="best">
                                <p>123123.12 WAX</p>
                            </div>
                            <div className="volume">
                                <p>100500.54 WAX</p>
                            </div>
                        </div>
                    </Link>
            ))}
            <div className="load-more">
                <OrangeBorderButton onClick={handleLoadMore}>Load more</OrangeBorderButton>
            </div>
        </div>
    )
};

export default FilteredTable;
