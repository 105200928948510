import React, {useState, useEffect, useContext} from 'react';

import Switcher from "../../components/Switcher/Switcher";
import OrangeBorderButton from "../../components/OrangeBorderButton/OrangeBorderButton";
import PoolItem from "../../components/PoolItem/PoolItem";

import {getBuyPoolsByCollections, getSellPoolsByCollections} from "../../services/wax.services";

import {UALContext} from "ual-reactjs-renderer";

import './Pool.scss';



const Pool = () => {
    const {activeUser} = useContext(UALContext);
    const [checked, setChecked] = React.useState(true);
    const [sellPools, setSellPools] = useState([]);
    const [buyPools, setBuyPools] = useState([]);


    useEffect(() => {
        const fetchSellPools = async () => {
            try {
                const data = await getSellPoolsByCollections();
                const filteredData = data.filter(
                    (pool) =>
                        pool.pool_owner === activeUser.accountName &&
                        pool.token_contract === "eosio.token" &&
                        pool.floor_price.includes("WAX")
                );
                setSellPools(filteredData);
            } catch (error) {
                console.log(error);
            }
        };

        const fetchBuyPools = async () => {
            try {
                const data = await getBuyPoolsByCollections();
                const filteredData = data.filter(
                    (pool) =>
                        pool.pool_owner === activeUser.accountName &&
                        pool.token_contract === "eosio.token" &&
                        pool.floor_price.includes("WAX")
                );
                setBuyPools(filteredData);
            } catch (error) {
                console.log(error);
            }
        };

        fetchSellPools();
        fetchBuyPools();
    }, []);

    return (
        <div className={'poole-section'}>
            <div className="poole-section-title">
                <h2>Pools Overview</h2>
                <p>View all of the pools that {activeUser?.accountName} provides liquidity for</p>
                <Switcher checked={checked} setChecked={setChecked} label={checked ? 'Hiding empty pool' : 'Showing empty pools'} />
                <OrangeBorderButton width={179} height={44} link={'/create'}>+ Create New Pool</OrangeBorderButton>
            </div>

            {(sellPools.length > 0 || buyPools.length > 0) && (
                <div className="poole-section-content">
                    <div className="side-bar">
                        <div className="show-hidden-pools">
                            <div className="show-hidden-pools_title">
                                {/*<label htmlFor="">Show Hidden Pools</label>*/}
                                {/*<Switcher checked={checked} setChecked={setChecked} />*/}
                            </div>
                            <input className={'search'} type="text" placeholder={'Search collection'}/>
                        </div>
                        <div className="collections">
                            <p>Collections</p>
                            <select>
                                <option>All</option>
                            </select>
                        </div>
                        <div className="schemas">
                            <p>Schemas</p>
                            <div className="schemas-list">
                                <p>Lorem input</p>
                                <p>Lorem input</p>
                                <p>Lorem input</p>
                                <p>Lorem input</p>
                                <p>Lorem input</p>
                                <p>Lorem input</p>
                                <p>Lorem input</p>
                                <p>Lorem input</p>
                                <p>Lorem input</p>
                                <p>Lorem input</p>
                                <p>Lorem input</p>

                            </div>
                        </div>
                        <div className="tool-type">
                            <p>Tool type</p>
                            <div className="tool-type-block">
                                <p>NFT</p>
                                <input type="checkbox"/>
                            </div>
                            <div className="tool-type-block">
                                <p>Token</p>
                                <input type="checkbox"/>
                            </div>
                            <div className="tool-type-block">
                                <p>Trade</p>
                                <input type="checkbox"/>
                            </div>
                        </div>
                    </div>
                    <div className="poll-list">
                        <PoolItem filteredSellPools={sellPools} filteredBuyPools={buyPools} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Pool;
