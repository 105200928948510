import React, {useContext, useState, useEffect} from 'react'
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {UALContext} from "ual-reactjs-renderer";

import OrangeBorderButton from "../OrangeBorderButton/OrangeBorderButton";
import OrangeButton from "../OrangeButton/OrangeButton";

import Logo from '../../images/logo.png'
import CartIcon from '../../images/cart.svg'

import './Header.scss';

import {getCollectionsSearch} from "../../services/wax.services";
import {toast} from "react-toastify";



function Header({balance}) {
    const { activeUser, showModal, logout } = useContext(UALContext);
    const allCollections = useSelector(state => state.allCollections)
    const [searchText, setSearchText] = useState('');
    const [filteredCollections, setFilteredCollections] = useState([])


    useEffect(() => {
        if (searchText.length > 3) {
            getCollectionsSearch(searchText)
                .then((data) => {
                    setFilteredCollections(data);
                })
                .catch((e) => console.log(e));
        }
    }, [searchText]);
    
    const handleConnect = () => {
        showModal()
    }

    const handleLogOut = () => {
        logout()
    }

    // const handleSearchInputChange = (event) => {
    //     setSearchText(event.target.value);
    //     const filteredCollections = allCollections.filter((collection) => {
    //         return collection.collection_name.toLowerCase().includes(event.target.value.toLowerCase());
    //     });
    //     setFilteredCollections(filteredCollections);
    // };

    const handleSearchInputChange = (event) => {
        setSearchText(event.target.value);
    };
    //
    // function handletest() {
    //     toast.success('🦄 Wow so easy!', {
    //         position: "bottom-right",
    //         autoClose: 5000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //         theme: "light",
    //     });
    // }

    return (
        <header className="header">
            <div className="header-container">
                <div className="header-left">
                    <Link to={'/'}>
                        <div className="header-left_logo">
                            <img src={Logo} alt=""/>
                        </div>
                    </Link>
                    <div className="header-left_search">
                        <input type="text" placeholder={'Search collections'} onChange={handleSearchInputChange} value={searchText}/>
                        {searchText.length > 2 && (
                            <ul className="header-left_search-results">
                                {filteredCollections.map((collection, index) => (
                                    <Link to={`/collections/${collection.collection_name}`}>
                                        <li onClick={() => setSearchText('')} key={index}>{collection.collection_name}</li>
                                    </Link>
                                ))}
                            </ul>
                        )}
                        <Link to={'/collections'}>
                            <OrangeBorderButton width={136} link={'/collections'}>Collections</OrangeBorderButton>
                        </Link>
                    </div>
                </div>
                <div className="header-right">
                    <div className="header-right_logIn">
                        {activeUser &&
                            <div className={'token-block'}>
                                <div className="token-icon">
                                    <img src={Logo} alt=""/>
                                </div>
                                <div className="token-amount">
                                    <p>{balance}</p>
                                </div>
                            </div>
                        }
                        {/*<OrangeBorderButton>Your NFTs</OrangeBorderButton>*/}
                        <OrangeBorderButton link={'/pool'}>Your Pools</OrangeBorderButton>
                        {!activeUser ?
                            <OrangeButton onClick={handleConnect}>Connect</OrangeButton>
                            :
                            <div className={'user-block'}>
                                <div className="avatar" onClick={handleLogOut}></div>
                                <div className="user-name">
                                    <p>{activeUser.accountName}</p>
                                </div>
                                <div className="arrow"></div>
                            </div>
                        }
                    </div>
                    {/*<div className="header-right_cart">*/}
                    {/*    <img src={CartIcon} alt=""/>*/}
                    {/*</div>*/}
                </div>
            </div>
        </header>
    );
}

export default Header;
