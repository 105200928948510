import React, { useContext, useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { UALContext } from 'ual-reactjs-renderer';

import {getNftsByIds, getSellPoolsByCollections} from '../../services/wax.services';

import SliderS from "../../components/Slider/Slider";

import './PoolPageSell.scss';
import {LinearDiagramForSellPool} from "../../components/LinearDiagramForSellPool/LinearDiagramForSellPool";
import WaxLogo from "../../images/wax_logo2.png";
import ArrowIcon from "../../images/arrow2.png";
import {
    ExponentialDiagramForSellPool
} from "../../components/ExponentialDiagramForSellPool/ExponentialDiagramForSellPool";



const PoolPageSell = () => {
    const { name, id } = useParams();
    const navigate = useNavigate();
    const { activeUser } = useContext(UALContext);

    const [currentPool, setCurrentPool] = useState(null);
    const [delta, setDelta] = useState(0);
    const [amountNFT, setAmountNFT] = useState(0);

    const [isEditing, setIsEditing] = useState(false);
    const [editedPool, setEditedPool] = useState(null);
    const [editedDelta, setEditedDelta] = useState(null);

    const [sliderValue, setSliderValue] = useState(0)

    const [price, setPrice] = useState(0)
    const [priceExpon, setPriceExpon] = useState(0)

    const [isEditingDeposite, setIsEditingDeposite] = useState(false);
    const [amountDeposite, setAmountDeposite] = useState(currentPool?.pool_asset);

    const [currentDelta, setCurrentDelta] = useState('Linear')
    const [NFTonSale, setNFTonSale] = useState([])


    const toggleEditing = () => {
        setIsEditingDeposite(!isEditingDeposite);
    };

    const handleSave = () => {
        // handleDeposit(amountDeposite);
        setIsEditingDeposite(false);
    };

    const handleClickBack = () => {
        navigate(`/collections/${name}`);
    };

    const handleClickDirect = () => {
        navigate(`/collections/${name}/swap/sell/${id}`);
    }

    useEffect(() => {
        const fetchPool = async () => {
            try {
                const sellPools = await getSellPoolsByCollections();
                const pool = sellPools.find((item) => item.pool_id === parseInt(id));
                setCurrentPool(pool);


                if (pool.linear_delta === 0 && pool.exponential_delta === "0.00000000000000000") {
                    setCurrentDelta('Linear')
                } else if (pool.linear_delta !== 0) {
                    setCurrentDelta('Linear')
                } else {
                    setCurrentDelta('Exponential')
                }


            } catch (error) {
                console.log(error);
            }
        };

        fetchPool();
    }, [id]);

    useEffect(() => {
        getNftsByIds(currentPool?.pool_nfts)
            .then((data) => {
                setNFTonSale(data)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [currentPool])

    useEffect(() => {
        if (sliderValue === 0) {
            setPriceExpon(0);
        } else if (sliderValue === 1) {
            setPriceExpon(Number(currentPool?.floor_price.split(' ')[0]));
        } else {
            let price = Number(currentPool?.floor_price.split(' ')[0]);
            let totalPrice = price;
            for (let i = 2; i <= Number(sliderValue); i++) {
                price += price * (delta / 100);
                totalPrice += price;
            }
            setPriceExpon(totalPrice);
        }
    }, [currentPool, sliderValue, delta]);




    useEffect(() => {
        let price = 0;
        let currentDelta = Number(delta);

        if (sliderValue > 0) {
            price = Number(currentPool?.floor_price.split(' ')[0]);
            const calculatePrice = (deltaSign) => {
                for (let i = 2; i <= Number(sliderValue); i++) {
                    price += Number(currentPool?.floor_price.split(' ')[0]) + deltaSign * currentDelta;
                    currentDelta += Number(delta);
                }
                setPrice(price);
            };
            calculatePrice(1);
        } else {
            setPrice(0);
        }
    }, [currentPool, sliderValue, delta]);


    useEffect(() => {
        if (!currentPool) return;
        if (currentPool.linear_delta > 0) {
            setDelta(currentPool.linear_delta / Math.pow(10,8));
        } else if (currentPool.exponential_delta !== '0.00000000000000000') {
            setDelta(parseFloat(currentPool.exponential_delta));
        } else {
            setDelta(0);
        }
    }, [currentPool]);

    const handleEditClick = () => {
        setIsEditing(true);
        setEditedPool(currentPool.floor_price);
        setEditedDelta(delta);
    };

    const handleSaveClick = () => {
        setIsEditing(false);
        setCurrentPool({
            ...currentPool,
            floor_price: editedPool,
            linear_delta: editedDelta,
        });
    };


    return (
        <div className={'pool-page-section'}>
            <div className="pool-page-section_title">
                <div className="name-collection">
                    <p>{name}</p>
                </div>
                <div className="arrow">
                    <img src={ArrowIcon} alt=""/>
                </div>
                <div className="wax-title">
                    <img src={WaxLogo} alt=""/>
                    <p>WAX</p>
                </div>
            </div>

            <div className="action-buttons">
                <button onClick={handleClickBack} className={'view-all'}>View All</button>
                <button onClick={handleClickDirect} className={'direct-swap'}>Direct Pool Swap</button>
            </div>

            <div className="assets-block-sell">
                <div className="assets">
                    <div className="assets_title">
                        <h5>Assets</h5>
                    </div>

                    <div className="deposit-field">
                        <div className="wrapper">
                            <div className="nfts">
                                <p>NFTs</p>
                                <p>{currentPool?.pool_nfts.length === 0 ? 'Empty pool' : currentPool?.pool_nfts.length}</p>
                            </div>
                            {activeUser && currentPool && currentPool.pool_owner && activeUser.accountName === currentPool.pool_owner && (
                                <div className="deposit-withdraw-btn">
                                    {isEditingDeposite ? (
                                        <button className="save-btn" onClick={handleSave}>
                                            Save
                                        </button>
                                    ) : (
                                        <button className="deposit-btn" onClick={toggleEditing}>
                                            Deposit
                                        </button>
                                    )}
                                    <button className="withdraw-btn">Withdraw</button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="nfts-field">
                        <div className="nfts-field-list">
                            {NFTonSale.map(item =>
                                <div className="nfts-field-list_item">
                                    <div className="nfts-field-list_item-image">
                                        {item.data.img?.startsWith('Qm') ? (
                                            <img src={`https://atomichub-ipfs.com/ipfs/${item.data.img}`} alt=""/>
                                        ) : (
                                            <img src={item.data.img} alt=""/>
                                        )}
                                    </div>
                                    <div className="nfts-field-list_item-info">
                                        <p>#{item.asset_id}</p>
                                        <h6>{item.data.name}</h6>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="about">
                    <div className="pricing">
                        {activeUser && currentPool && currentPool.pool_owner && activeUser.accountName === currentPool.pool_owner &&
                            <div className="edit">
                                {isEditing ? (
                                    <button onClick={handleSaveClick}>Save</button>
                                ) : (
                                    <button onClick={handleEditClick}>Edit</button>
                                )}
                            </div>
                        }

                        <div className="pricing_title">
                            <h5>Pricing</h5>
                        </div>
                        <div className="info">
                            <div className="current-price">
                                <div className="wrapper">
                                    <div className="little-title">
                                        <p>Current Price</p>
                                    </div>
                                    {isEditing ? (
                                        <input
                                            type="number"
                                            value={editedPool}
                                            onChange={(event) => setEditedPool(event.target.value)}
                                        />
                                    ) : (
                                        <p className={'floor_price'}>{currentPool?.floor_price}</p>
                                    )}
                                </div>
                            </div>
                            <div className="delta">
                                <div className="wrapper">
                                    <div className="little-title">
                                        <p>Delta: {currentDelta}</p>
                                    </div>
                                    {isEditing ? (
                                        <input
                                            type="number"
                                            value={editedDelta}
                                            onChange={(event) => setEditedDelta(event.target.value)}
                                        />
                                    ) : (
                                        <>
                                            {currentDelta === 'Linear' &&
                                                <div className={'delta-number'}>
                                                    <p>{delta} WAX</p>
                                                </div>
                                            }
                                            {currentDelta === 'Exponential' &&
                                                <div className={'delta-number'}>
                                                    <p>{delta}%</p>
                                                </div>
                                            }
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="about-owner">
                        <div className="about-owner_title">
                            <h5>About</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="diagram-block">
                <div className="diagram-block_title">
                    <h5>Price Graph</h5>
                </div>
                <div className="amount-slider">
                    <h4>Buying {sliderValue} NFTs</h4>
                    <SliderS setSliderValue={setSliderValue} slideValue={sliderValue}  amount={currentPool?.pool_nfts.length} />
                    {currentDelta === 'Linear' &&
                        <p>will cost you {price.toFixed(8)} WAX</p>
                    }
                    {currentDelta === 'Exponential' &&
                        <p>will cost you {priceExpon.toFixed(8)} WAX</p>
                    }

                </div>
                {currentDelta === 'Linear' &&
                    <LinearDiagramForSellPool startPrice={currentPool?.floor_price.split(' ')[0]} delta={delta} amount={currentPool?.pool_nfts.length} slideValue={sliderValue} />
                }
                {currentDelta === 'Exponential' &&
                    <ExponentialDiagramForSellPool startPrice={currentPool?.floor_price.split(' ')[0]} delta={delta} amount={currentPool?.pool_nfts.length} slideValue={sliderValue} />
                }
            </div>
        </div>
    )
};

export default PoolPageSell;
