import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {getBuyPoolsByCollections, getCollectionByName, getSellPoolsByCollections} from '../../services/wax.services';
import testBg from '../../images/background_bc.png';
import goldLogo from '../../images/gold_logo.png';
import PoolItem from "../../components/PoolItem/PoolItem";
import './CollectionPage.scss';


const CollectionPage = () => {
    const location = useLocation();
    const [name, setName] = useState('');
    const [tabActive, setTabActive] = useState(1);
    const [sellPools, setSellPools] = useState([]);
    const [buyPools, setBuyPools] = useState([]);
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');


    const [currentCollection, setCurrentCollection] = useState([]);
    const [imageSource, setImageSource] = useState(null);

    useEffect(() => {
        const currentPath = location.pathname;
        const collectionName = currentPath.split('/')[2];
        setName(collectionName);
    }, [location]);

    useEffect(() => {
        if (currentCollection?.data?.img?.startsWith('Qm')) {
            setImageSource(`https://atomichub-ipfs.com/ipfs/${currentCollection.data.img}`);
        } else {
            setImageSource(currentCollection?.data?.img);
        }
    }, [currentCollection]);

    useEffect(() => {
        getCollectionByName(name)
            .then((data) => {
                setCurrentCollection(data)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [name])

    console.log(currentCollection)

    useEffect(() => {
        const sellPools = () => {
            getSellPoolsByCollections()
                .then((data) => setSellPools(data))
                .catch((error) => console.log(error))
        }

        const buyPools = () => {
            getBuyPoolsByCollections()
                .then((data) => setBuyPools(data))
                .catch((error) => console.log(error))
        }

        sellPools()
        buyPools()
    }, []);

    const handleTabClick = (tabIndex) => {
        setTabActive(tabIndex);
    };

    const handleMinPriceChange = (event) => {
        setMinPrice(event.target.value);
    };

    const handleMaxPriceChange = (event) => {
        setMaxPrice(event.target.value);
    };

    const filteredSellPools = sellPools.filter(pool => {
        const floorPrice = parseFloat(pool.floor_price.split(' ')[0]);
        return (
            pool.NFTs_collection === name &&
            pool.floor_price.includes('WAX') &&
            (minPrice === '' || floorPrice >= parseFloat(minPrice)) &&
            (maxPrice === '' || floorPrice <= parseFloat(maxPrice))
        );
    }).sort((a, b) => parseFloat(a.floor_price.split(' ')[0]) - parseFloat(b.floor_price.split(' ')[0]));

    const filteredBuyPools = buyPools.filter(pool => {
        const floorPrice = parseFloat(pool.floor_price.split(' ')[0]);
        return (
            pool.NFTs_collection === name &&
            pool.floor_price.includes('WAX') &&
            (minPrice === '' || floorPrice >= parseFloat(minPrice)) &&
            (maxPrice === '' || floorPrice <= parseFloat(maxPrice))
        );
    }).sort((a, b) => parseFloat(a.floor_price.split(' ')[0]) - parseFloat(b.floor_price.split(' ')[0]));

    const handlerSelectedPool = (item) => {
        console.log(item)
    }

    return (
        <>
            <div className={'collection'} style={{ backgroundImage: `url(${testBg})` }}>
                <div className="collection-header">
                    <div className="collection-header_name">
                        <div className="logo">
                            {imageSource &&
                                <img src={imageSource} alt=""/>
                            }
                        </div>
                        <p>{name}</p>
                    </div>
                    <div className="collection-header_about">
                        <div className="collection-header_about_wrapper">
                            <div className="floor">
                                <h6>Floor price</h6>
                                <p>2,556 WAX</p>
                            </div>
                            <div className="best">
                                <h6>Best Offer</h6>
                                <p>10,902.98 WAX</p>
                            </div>
                            <div className="volume">
                                <h6>Volume 24h</h6>
                                <p>231,902.98 WAX</p>
                            </div>
                            <div className="total">
                                <h6>Total Volume</h6>
                                <p>231,902.98 WAX</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="collection-content">
                <div className="collection-content_header">
                    <p className={tabActive === 1 ? 'active' : ''} onClick={() => handleTabClick(1)}>
                        Pools ({filteredSellPools.length + filteredBuyPools.length})
                    </p>
                    <p className={tabActive === 2 ? 'active' : ''} onClick={() => handleTabClick(2)}>
                        Activity
                    </p>
                </div>
                {tabActive === 1 && (
                    <div className={'collection-content_container'}>
                        <div className="collection-content_sidebar">
                            <div className="min-max-price">
                                <p>Price</p>
                                <div className="inputs">
                                    <input type="text" className="min" placeholder={'Min price (WAX)'} onChange={(e) => handleMinPriceChange(e)}/>
                                    <input type="text" className="max" placeholder={'Max price (WAX)'} onChange={(e) => handleMaxPriceChange(e)}/>
                                </div>
                            </div>
                            <div className="pool-type">
                                <p className={'pool-type_p'}>Pool type</p>
                                <div className="pool-type_item">
                                   <p>NFT ({filteredBuyPools.length})</p>
                                    <input type="checkbox" className={'custom-checkbox'}/>
                                </div>
                                <div className="pool-type_item">
                                    <p>Token ({filteredSellPools.length})</p>
                                    <input type="checkbox" className={'custom-checkbox'}/>
                                </div>
                                <div className="pool-type_item">
                                    <p>Trade (0)</p>
                                    <input type="checkbox" className={'custom-checkbox'}/>
                                </div>
                            </div>
                            <div className="bonding-curve">
                                <p className={'bonding-curve_p'}>Bonding curve</p>
                                <div className="bonding-curve_item">
                                    <p>Linear (0)</p>
                                    <input type="checkbox" className={'custom-checkbox'}/>
                                </div>
                                <div className="bonding-curve_item">
                                    <p>Exponential (0)</p>
                                    <input type="checkbox" className={'custom-checkbox'}/>
                                </div>
                            </div>
                        </div>
                        <div className="collection-content_list">
                            <PoolItem filteredSellPools={filteredSellPools} filteredBuyPools={filteredBuyPools} handlerSelectedPool={handlerSelectedPool} collectionsName={name}/>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default CollectionPage;
