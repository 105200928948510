import React, {useEffect, useContext, useState} from "react";
import { Route, Routes } from 'react-router-dom';
import {getBalance, getCollections, getUserCollections} from "./services/wax.services";
import {useDispatch} from "react-redux";
import {UALContext} from "ual-reactjs-renderer";


import Header from "./components/Header/Header";
import MainPage from "./pages/MainPage";
import CollectionsPage from "./pages/CollectionsPage/CollectionsPage";
import Pool from "./pages/Pool/Pool";
import CreatePool from "./pages/CreatePool/CreatePool";
import CollectionPage from "./pages/CollectionPage/CollectionPage";
import NotFound from "./pages/NotFound/NotFound";
import PoolPageBuy from "./pages/PoolPageBuy/PoolPageBuy";
import PoolPageSell from "./pages/PoolPageSell/PoolPageSell";
import SwapPage from "./pages/SwapPage/SwapPage";
import {ToastContainer} from "react-toastify";

import './App.scss';



function App() {
    const dispatch = useDispatch()
    const {activeUser} = useContext(UALContext);
    const [balance, setBalance] = useState(0)

    useEffect(() => {
        getCollections()
            .then((data) => {
                dispatch({type: "ALL_COLLECTIONS", payload: data})
            })
            .catch((e) => console.log(e));
    }, [dispatch]);

    useEffect(() => {
        if (activeUser && activeUser.accountName) {
            getUserCollections(activeUser)
                .then((data) => {
                    dispatch({type: "ALL_USER_COLLECTIONS", payload: data})
                })
                .catch((error) => {
                    console.log(error)
                })
        }

    }, [activeUser])

    // useEffect(() => {
    //     getCollectionsSearch()
    //         .then((data) => {
    //             dispatch({type: "ALL_COLLECTIONS", payload: data})
    //         })
    //         .catch((e) => console.log(e));
    // }, [dispatch]);


    useEffect(() => {
        getBalance({user: activeUser?.accountName})
            .then((data) => {
                setBalance(data)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [activeUser])

    return (
        <div className="App">
            <Header balance={balance} />
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/collections" element={<CollectionsPage />} />
                <Route path="/collections/:name" element={<CollectionPage />} />
                <Route path="/collections/:name/buy/:id" element={<PoolPageBuy />} />
                <Route path="/collections/:name/sell/:id" element={<PoolPageSell />} />

                <Route path="/collections/:name/swap/buy/:id" element={<SwapPage />} />
                <Route path="/collections/:name/swap/sell/:id" element={<SwapPage />} />

                <Route path="/pool" element={<Pool />} />

                <Route path="/create" element={<CreatePool />} />

                <Route path="*" element={<NotFound />} />
            </Routes>


            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
}

export default App;
