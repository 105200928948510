import React, {useContext} from 'react'
import {useNavigate} from "react-router-dom";
import OrangeBorderButton from "../../OrangeBorderButton/OrangeBorderButton";
import {buyPoolInit, sellPoolInit} from "../../../services/wax.services";
import {UALContext} from "ual-reactjs-renderer";
import {BUY, SELL} from "../../../constants/pool.constants";
import {toast} from "react-toastify";


function FourthStep({currentStep, delta, schema, templates, collection, startPrice, waxTokenConfig, exponDelta, selectedOption, selectedItemFromSell, priceTotal, priceExponTotal, totalPrice}) {
    const navigate = useNavigate();
    const {activeUser} = useContext(UALContext);

    console.log(totalPrice)
    
    const handlerCreateBuyPool = () => {
        buyPoolInit(activeUser, startPrice, collection?.collection_name, schema , templates , delta, exponDelta, waxTokenConfig, totalPrice)
            .then(() => {
                toast.success('Pool created', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

                navigate('/pool')
            })
            .catch((error) => {
                const errorMessage = error.message.replace('assertion failure with message: ', '');
                toast.error(errorMessage, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                console.log(error);
            })
    }

    const handlerCreateSellPool = () => {
        sellPoolInit(activeUser, startPrice, collection, schema = [], templates = [], delta, exponDelta, waxTokenConfig, selectedItemFromSell)
            .then(() => {

                toast.success('Pool created', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

                navigate('/pool')

            })
            .catch((error) => {
                const errorMessage = error.message.replace('assertion failure with message: ', '');
                toast.error(errorMessage, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                console.log(error);
            })
    }

    return (
            <div className={`create-pool-stepper-fourth ${currentStep !== 4 ? 'hidden' : ''}`}>
                {selectedOption === BUY &&
                    <>
                        <h5>Your Pool Details</h5>
                        <div className="finish-text">
                            <p>You are depositing <span>{totalPrice} WAX</span> to buy up to <span>{collection?.collection_name}</span> NFTs</p>
                            <p>Your pool will have a starting price of <span>{startPrice} WAX</span></p>
                            <p>Each time your pool buys an NFT, your price will adjust down by <span>{delta} WAX</span></p>
                        </div>
                        <OrangeBorderButton onClick={() => handlerCreateBuyPool()}>Create Pool</OrangeBorderButton>
                    </>
                }

                {selectedOption === SELL &&
                    <>
                        <div className={`create-pool-stepper-fourth ${currentStep !== 4 ? 'hidden' : ''}`}>
                            <h5>Your Pool Details</h5>
                            <div className="finish-text">
                                <p>You are depositing <span>{selectedItemFromSell.length} alien.worlds NFTs</span> to buy up to <span>N WAX</span></p>
                                <p>Your pool will have a starting price of <span>{startPrice} WAX</span></p>
                                <p>Each time your pool sells an NFT, your price will adjust up by <span>3 WAX</span></p>
                            </div>
                            <div className="selected-nfts-list">
                                {selectedItemFromSell.map((item) =>
                                    <div className="selected-nfts-list_item">
                                        <div className="image">
                                            {item.data.img?.startsWith('Qm') ? (
                                                <img src={`https://atomichub-ipfs.com/ipfs/${item.data.img}`} alt=""/>
                                            ) : (
                                                <img src={item.data.img} alt=""/>
                                            )}
                                        </div>
                                        <div className="collection-block">
                                            <p>{item.collection.collection_name}</p>
                                        </div>
                                        <div className="asset-id">
                                            <p>#{item.asset_id}</p>
                                        </div>
                                    </div>
                                    )
                                }
                            </div>
                            <OrangeBorderButton onClick={() => handlerCreateSellPool()}>Create Pool</OrangeBorderButton>
                        </div>
                    </>
                }
            </div>


    )
}

export default FourthStep;
