import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import WaxIcon from '../../images/wax_logo.png';
import './PoolItem.scss';
import {ArrowLin} from "../../images/SVGElements/ArrowLin";
import {ArrowExpon} from "../../images/SVGElements/ArrowExpon";

const PoolItem = ({ filteredSellPools, filteredBuyPools, handlerSelectedPool, collectionsName }) => {
    const formatPrice = (price) => {
        const formattedPrice = parseFloat(price.split(' ')[0]).toFixed(8).replace(/\.?0+$/, '');
        return formattedPrice;
    };

    return (
        <>
            {filteredSellPools.concat(filteredBuyPools).map((item) => {
                const deltaType = parseFloat(item.linear_delta) !== 0 ? <span className={'item-delta'}><p>lin</p><ArrowLin /></span> : parseFloat(item.exponential_delta) !== 0 ? <span className={'item-delta'}><p>exp</p><ArrowExpon /></span> : '';
                const poolType = filteredBuyPools.includes(item) ? 'buy' : 'sell';
                const itemType = filteredBuyPools.includes(item) ? 'Buy' : 'Sell';

                const poolLink = filteredSellPools.includes(item)
                    ? `/collections/${collectionsName ? collectionsName : item.NFTs_collection}/sell/${item.pool_id}`
                    : `/collections/${collectionsName ? collectionsName : item.NFTs_collection}/buy/${item.pool_id}`;

                return (
                    <Link key={item.pool_id} to={poolLink}>
                        <div className="item" onClick={() => {}}>
                            <div className="item-wrapper">
                                <p className={`item-type ${poolType}`}>{itemType}</p>
                                <div className="container">
                                    <div className="container-wrapper">
                                        <div className="price">
                                            <div className="price-left">
                                                <p className={`price-left-name ${poolType}`}>
                                                    {itemType} price
                                                </p>
                                                <div className="price-left_top_amount">
                                                    <img src={WaxIcon} alt="" />
                                                    <p className="price-left-amount">
                                                        {formatPrice(item.floor_price)}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="price-right">
                                                <p className="price-right-name"></p>
                                                <p className="price-right-amount">-</p>
                                            </div>
                                        </div>
                                        <div className="delta-balance">
                                            <div className="delta">
                                                <p className="delta-p">
                                                    <p>Delta</p> <span className="delta-p-type">{deltaType}</span>
                                                </p>
                                                <p className="delta-amount">
                                                    {parseFloat(item.exponential_delta) !== 0 ? Number(item.exponential_delta).toFixed(0) + '%' : (item.linear_delta / Math.pow(10, 8))}
                                                </p>
                                            </div>
                                            <div className="balance">
                                                <p className="balance-p">Balance</p>
                                                <div className="balance-wax">
                                                    {itemType === 'Buy' &&
                                                        <>
                                                            <img src={WaxIcon} alt=""/>
                                                            <p className="balance-amount">
                                                                {item.pool_asset}
                                                            </p>
                                                        </>
                                                    }
                                                    {itemType === 'Sell' &&
                                                        <p className="balance-amount">
                                                            {item.pool_nfts.length}
                                                        </p>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="pool-info">
                                    <div className="pool-info_owner">
                                        <p className="first">Owner</p>
                                        <p className="second">{item.pool_owner}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                );
            })}
        </>
    );
};

PoolItem.propTypes = {
    filteredSellPools: PropTypes.array.isRequired,
    filteredBuyPools: PropTypes.array.isRequired,
    collectionsName: PropTypes.string.isRequired,
};

export default PoolItem;
